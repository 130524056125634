import React from "react"
import parse from 'html-react-parser'; 

import { StaticQuery, graphql } from "gatsby"
import { SectionHeader } from "../common/section-header"

class LearningList extends React.Component {

  constructor(props) {
    super(props); 

    this.css = this.props.css ? this.props.css : 'container'; 
    this.renderArticles = this.renderArticles.bind(this); 
  }

  renderArticles(articles) {

    return (
      <section id='learning-center'>  
        <SectionHeader name='Learning Center' />      

        { articles.map( item => (
              <React.Fragment key={item.id}>
              	<div class="article-item">
					<label>Learning Center | {new Date(item.date).toISOString().slice(0,10)}</label>
					<h2><a href={item.path}>{ parse(item.title)}</a></h2>
					<div class="byline">By Facts First</div>
					{ parse(item.excerpt) }
                </div>
              </React.Fragment>
          )
        )}
      </section>
    );
  }

  render() {
   return (
     <div>
       <StaticQuery
         query={graphql`
           {
             allWordpressPost(
               limit: 10
               sort: { order: DESC, fields: date }
               filter: { categories: { elemMatch: { name: { eq: "Learning Center" } } } }
             ) {
               nodes {
                 content
                 excerpt
                 categories {
                   name
                 }
                 date
                 path
                 slug
                 title
                 id
                 acf {
                   image {
                     id
                     source_url
                     title
                     caption
                     alt_text
                     localFile {
                       childImageSharp {
                         fluid(maxWidth: 600, maxHeight: 400) {
                           ...GatsbyImageSharpFluid_noBase64
                         }
                       }
                     }
                   }
                 }
               }
             }
           }
         `}
         render={data => {
           const result = data.allWordpressPost.nodes;

           if (!result) {
             console.error(
               `Could not find articles for this: ${this.props.name} was not found in graphql query for section, content could not be displayed.`
             );
             return <div />;
           } else {
             return this.renderArticles(result);
           }
         }}
       />
     </div>
   );
}  

};

export default LearningList ; 