import React, { Component } from "react"
import Helmet from 'react-helmet'; 

// Common components & Layout 
import Layout from "../../layouts"; 
import { Container, Row, Col } from "react-bootstrap";

// Individual components that make up the page
import Newsletter from "../../components/newsletter/newsletter";
import Hero from "../../components/common/Hero"; 
import LearningList from "../../components/learning/LearningList";
import SEO from "../../components/common/SEO"; 

class LearningCenter extends Component {
  render() {
      return (
        <Layout>
          <Hero title="Learning Center" />  
          <Helmet bodyAttributes={{ class: "articles-page" }} />
          <SEO post={{
            title:"Facts First Learning Center",
            description: "Curious what metrics matter the most when measuring the health of an economy? Learn what those metrics are, and how to interpret those numbers."
          }} />
    
          <Container>
            <Row className={"latest"}>
              <Col>
                <LearningList />
              </Col>
            </Row>           
          </Container>
          <Newsletter />
        </Layout>
      ); 
  }
}

export default LearningCenter; 